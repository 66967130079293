<template>
  <section
    class="hero is-fullheight"
    style="background-color: #F1F1F1;"
  >
    <vue-headful :title="pageTitle" />
    <div class="hero-body">
      <div
        class="container"
        style="width:99vw;"
      >
        <div class="columns">
          <div class="column"></div>
          <div class="column">
            <div id="login">
              <div v-if="(phoneNumber && phoneNumber !== '') || (email && email !== '')">
                <figure class="has-text-centered">
                  <img
                    :src="activeSite.logoUrl"
                    style="max-width: 250px;"
                  />
                </figure>
                <div v-show="failedLoginMessage !== ''">
                  <i class="fas fa-times has-text-danger"></i>
                   {{ failedLoginMessage }}
                  <br>
                  <br>
                </div>
                  <div class="field">
                    <label class="label is-size-7">2FA Code</label>
                    <label class="label is-size-7 has-text-grey">The verification code will expire in 10 minutes.</label>
                    <div class="control">
                      <input
                        ref="code"
                        :class="' input ' + (failedLoginMessage !== '' ? ' is-danger ':'')"
                        type="text"
                        @keyup.enter="verify()"
                        v-model="verificationCode"
                      />
                    </div>
                    <label class="label is-size-7">Sent to: {{phoneNumber !== '' ? phoneNumber : email}}</label>

                    <label
                      class="has-text-grey"
                      style="width: 100%; line-height: .75rem; font-size: .75rem; padding: .15rem;"
                    >
                      Haven't received a code? <a :class="{ 'has-text-grey': codeWasResent }" :disabled="codeWasResent" href="javascript:void(0)" @click="resendCode">{{ codeWasResent ? 'Code Sent' : 'Resend Code' }}</a>
                    </label>
                  </div>
                  <div class="field is-pulled-right">
                    <div class="control" style="z-index: 100;">
                      <a
                        :disabled="verificationCode.length !== 6 || isVerifying"
                        class="button is-accent"
                        v-on:click="verify()"
                      >{{ isVerifying ? 'Verifying' : 'Verify' }}
                        <spin-loader style="margin-left: 5px;" v-if="isVerifying" />
                      </a>
                    </div>
                  </div>
              </div>
            </div>
          </div>
          <div class="column"></div>
        </div>
        <div
          style="width: 100%; word-wrap: none; white-space: nowrap; padding-top: 3rem!important; line-height: .75rem; font-size: .8rem; color: #00467f; font-weight: 600; opacity: .75; text-align: center;"
        >
          Please contact BCC Client Services at bccclientservices@bcc-usa.com, if any issues are encountered.
        </div>
      </div>
    </div>
  </section>

</template>

<script>
import { mapMutations, mapState } from 'vuex'
import {
  activeSite, updateSession
} from '../vuex-actions'

export default {
  name: 'Login',
  data () {
    return {
      pageName: 'Verify',
      emailString: '',
      phoneNumber: '',
      failedLoginMessage: '',
      verificationCode: '',
      isCodeSent: false,
      codeWasResent: false,
      isVerifying: false
    }
  },
  watch: {
    verificationCode () {
      this.verificationCode = this.verificationCode.replace(/\D/g, '').substr(0, 6)
    }
  },
  async mounted () {
    await this.getUser()
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return this.pageName + ' - ' + this.activeSite.displayName
    },
    email () {
      if (this.emailString.length < 3 || !this.emailString.includes('@')) {
        return ''
      }

      let emailParts = this.emailString.split('@')
      let emailName = `${emailParts[0]}`.slice(0, 1)
      let maskedEmailName = `${emailName}`
      let maskedEmailDomain = `${emailParts[1].split('.')[0]}`.slice(0, 1)

      for (let i = 0; i < emailParts[0].length - 1; i++) {
        maskedEmailName += '•'
      }

      maskedEmailName += `${emailParts[0]}`.slice(
        emailParts[0].length - 1,
        emailParts[0].length
      )

      for (let i = 1; i < emailParts[1].split('.')[0].length - 1; i++) {
        maskedEmailDomain += '•'
      }

      maskedEmailDomain +=
        `${emailParts[1].split('.')[0]}`.slice(
          emailParts[1].split('.')[0].length - 1,
          emailParts[1].split('.')[0].length
        ) +
        '.' +
        `${emailParts[1].split('.')[1]}`.slice(
          emailParts[1].split('.'[1]).length - 1,
          emailParts[1].split('.')[1].length
        )
      return `${maskedEmailName}@${maskedEmailDomain}`
    }
  },
  methods: {
    ...mapMutations([updateSession]),
    async requestCode () {
      try {
        let response = await this.axios.get('/mfa')

        if (response.status >= 200 && response.status < 300) {
          this.isCodeSent = true
        }
      } catch (error) {
        //
      } finally {
        this.$nextTick(() => this.$refs.code.focus())
      }
    },
    async resendCode () {
      this.codeWasResent = true

      await this.requestCode()

      let undo = () => {
        this.codeWasResent = false
      }

      setTimeout(undo, 1500)
    },
    async getUser () {
      try {
        let response = await this.axios.get(`users/${this.clientSession.userId}/mfa-options`)

        if (response.status >= 200 && response.status < 300) {
          if (!['Email', 'SMS'].includes(response.data.mfaChannel)) {
            this.$router.push({ name: '2FASetup' })
          }

          this.emailString = response.data.email
          this.phoneNumber = response.data.mfaChannel === 'SMS' ? response.data.mfaPhone : ''
          await this.requestCode()
        }
      } catch (error) {
        this.$router.push({ name: '2FASetup', params: { passwordWasValidated: true } })
      } finally {

      }
    },
    async verify () {
      this.isVerifying = true
      try {
        let response = await this.axios.post('/mfa/verify?code=' + this.verificationCode, {
          code: this.verificationCode
        })

        if (parseInt(response.status) >= 200 && parseInt(response.status) < 300) {
          let nav = { name: ['Staff', 'Manager'].includes(this.clientSession.userRole) ? 'staffDashboard' : 'Dashboard' }

          if (this.$router.currentRoute.query) {
            if (this.$router.currentRoute.query.toUrl) {
              nav.name = this.$router.currentRoute.query.toUrl
            }

            if (this.$router.currentRoute.query.fromUrl && this.$router.currentRoute.query !== undefined) {
              nav = this.$router.currentRoute.query.fromUrl
            }
          }

          this.clientSession.isMfaValid = true

          this[updateSession](this.clientSession)

          this.$router.push(nav).catch()
        } else if (response.status === 500) {
          this.failedLoginMessage = 'Something has gone wrong.'
        } else {
          this.failedLoginMessage = '2FA Code was invalid'
        }
      } catch (error) {
        this.failedLoginMessage = '2FA Code was invalid'
      } finally {
        this.isVerifying = false
      }
    }
  }
}
</script>
